
import { metaDataMixin } from '~/mixins'

export default {
  mixins: [
    metaDataMixin,
  ],
  layout: 'program-metterdaad',
  scrollToTop: false,
  async asyncData ({ $api, $config, store, error }) {
    const slug = 'eo-metterdaad'

    let publication = {}

    const [
      { data: { items: programs = [] } = {} },
      { data: { items: campaigns = [] } = {}} ,
      { data: { items: featuredCampaigns = [] } = {}} ,
      { data: { items: callToActions = [] } = {} },
      { data: { items: articles = [] } = {}} ,
      { data: { items: recommendedArticles = [] } = {}} ,
      { data: { item: playlist } = {} },
    ] = await Promise.all([
      // Get program for this slug
      $api.programs.getOneBySlug(slug, {
        domain: $config.preprEnvironmentDomainGeneriek,
      }),
      // Get campaigns
      $api.campaigns.getAll({
        domain: $config.preprEnvironmentDomainMetterdaad,
      }),
      // Get featured campaigns
      $api.campaigns.getAll({
        domain: $config.preprEnvironmentDomainMetterdaad,
        channel: 'featured',
        limit: 1,
      }),
      // Get newest call to action
      $api.calltoactions.getAll({
        channel: 'featured',
        domain: $config.preprEnvironmentDomainMetterdaad,
        limit: 1,
      }),
      // Get articles
      $api.articles.getAll({
        domain: $config.preprEnvironmentDomainMetterdaad,
      }),
      // Get recommended articles
      $api.articles.getAll({
        channel: 'recommended',
        domain: $config.preprEnvironmentDomainMetterdaad,
        limit: 3,
      }),
      // Get playlist
      $api.playlists.getOne('9d94ff32-3a8e-42e5-894f-8c00e2c44ebe'),
    ]);

    // Check if we found a valid publication
    if (!programs || !programs.length) {
      error({
        context: `Couldn't find program or podcast: ${slug}.`,
        message: '(404) Programma niet gevonden',
        severity: 'info',
        statusCode: 404,
      })
      return false
    }

    publication = programs[0]

    // Set breadcrumbs for the page
    store.dispatch('breadcrumbs/set', {
      path: `/programmas/${slug}`,
      eoRoot: true,
      floating: true,
      width: 10,
      items: [
        {
          to: '/programmas',
          label: 'Programma\'s',
        },
        {
          label: publication.title,
        },
      ],
    })

    return {
      ...publication,
      campaigns: campaigns,
      campaignsFeatured: featuredCampaigns,
      callToAction: callToActions[0],
      articles: articles,
      articlesRecommended: recommendedArticles,
      playlist: playlist,
    }
  },
  data () {
    return {
      activeTab: null,
      articles: [],
      articlesRecommended: [],
      broadcastChannel: null,
      callToAction: null,
      campaigns: [],
      campaignsFeatured: [],
      changedOn: null,
      content: [],
      extraContent: [],
      environmentId: null,
      genreName: null,
      hasMoreArticlesAvailable: true,
      hero: null,
      id: null,
      intro: null,
      location: null,
      logo: null,
      modelId: null,
      platforms: [],
      playlist: null,
      presenters: [],
      publishOn: null,
      seo: null,
      slug: null,
      social: null,
      stories: null,
      subtitle: null,
      tags: null,
      title: null,
      topic: null,
      type: null,
    }
  },
  computed: {
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
  },
}
